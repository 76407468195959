
import './AllProductCardv2.style.css'
import idformLogo  from '../../assets/idformGlasslogo.svg'
import usedbyidforms from '../../assets/usedbyLogoidforms.png'
import idpassGlass from '../../assets/idpassGlass.svg'
import idRadarGlass from '../../assets/idRadarGlass.svg'
import ComplianceTrackerGlass from '../../assets/ComplianceTrackerGlass.svg'
import BackgroundchecksGlass from '../../assets/BackgroundchecksGlass.svg'
import Stanbic from '../../assets/Stanbic.png';
import unionBank from  '../../assets/UnionBank.png';
import Telkom from  '../../assets/Telkom.png';
import Fedex from '../../assets/Fedex.png'
import Equity from '../../assets/Equity.png'
import Bolt from '../../assets/Bolt.png'
import USConsulate from '../../assets/USConsulate.png'
import FMCIDE from '../../assets/FMCIDE.png'
import Prembly from '../../assets/PremblyIco.png'

type Prop ={
    code:"COMPLIANCE_TRACKER"|"IDENTITYPASS"|"IDENTITYRADAR"|"IDENTITYFORMS"|"BACKGROUND-CHECK",
    name:string,
    productLink:string,
    content?:string,
    websiteLink:string
}
const AllProductCardv2 = ({websiteLink,code,name,productLink,content='Create forms that will stand out and convert'}:Prop)=>{

    const style ={
        'COMPLIANCE_TRACKER':{
            firstBtn:{color:'#FFFFFF',backgroundColor:'#027DA3'},
            secondBtn:{color:'#027DA3',backgroundColor:'#F4FBFA'},
            logo:ComplianceTrackerGlass,
            usedby:[Prembly]
        },
        'IDENTITYPASS':{
            firstBtn:{color:'#FFFFFF',backgroundColor:'#B853E6'},
            secondBtn:{color:'#B853E6',backgroundColor:'#F8EDFC'},
            logo:idpassGlass,
            usedby:[unionBank,Telkom]
        },
        'IDENTITYRADAR':{
            firstBtn:{color:'#FFFFFF',backgroundColor:'#CF19A0'},
            secondBtn:{color:'#CF19A0',backgroundColor:'#FBEEF8'},
            logo:idRadarGlass,
            usedby:[Stanbic,Fedex]
        },
        'IDENTITYFORMS':{
            firstBtn:{color:'#FFFFFF',backgroundColor:'#4161DF'},
            secondBtn:{color:'#0059D4',backgroundColor:'#F0F0FA'},
            logo:idformLogo,
            usedby:[USConsulate,FMCIDE]
        },
        'BACKGROUND-CHECK':{
            firstBtn:{color:'#FFFFFF',backgroundColor:'#0059D4'},
            secondBtn:{color:'#0059D4',backgroundColor:'#F0F0FA'},
            logo:BackgroundchecksGlass,
            usedby:[Equity,Bolt]
        },
    }
    //
    return (
        <div className='allproductcardv2__container'>
            <div className='allproductcardv2__title_container_section'>
                <img src={style[code].logo} alt={code} />
                {/* <p>IdentityForm</p> */}
                <p>{name}</p>
            </div>
            <p className='allproductcardv2__content'>
            {content}
            </p>


            <div className='allproductcardv2__usedby_container'>
                {
                    style[code].usedby.length!==0?
                <>
                                <p>Used By</p>
                <div className='allproductcardv2__each_usedby_container'>
                    {
                       style[code].usedby.map((d,index)=>(
                        <div key={index} className='allproductcardv2__each_usedby'>
                            <img src={d} alt="" />
                        </div>
                        ))
                    }
                </div>
                </>:''
                }

            </div>
            
            <div className='allproductcardv2__btn_container'>
                <button
                style={style[code].firstBtn}
                onClick={()=>{
                    window.open(productLink,'_blank')
                }}
                >Visit Product</button>
                <button
                onClick={()=>{
                    window.open(websiteLink,'_blank')
                }}
                style={style[code].secondBtn}
                >Request Demo</button>
            </div>

        </div>
    )
}

export default AllProductCardv2;
