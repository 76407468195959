// import passlogo from '../../assets/passlogo.png';
// import premlogo from '../../assets/form-icon-black.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationToast from '../../components/utils/notifToast';
import { allPremblyProductsRequest } from '../../redux/actions/allPremblyProducts';
import { RootState } from '../../redux/reducers';
// import Mainloader from '../../components/utils';
import Cookies from 'js-cookie';
import AllProductCardv2 from '../../components/AllProductCardv2/AllProductCardv2';
// import idpassLogo from "../../assets/idpass-logo.png"
// import bgcLogo from "../../assets/bgc-logo.png"

export default function AllProductsPage(props: any) {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')


    const allPremblyProductsState = useSelector((state: RootState) => state.allPremblyProductsReducer)
    const tenantState = useSelector((state: RootState) => state.tenantInfoReducer)

    const dispatch = useDispatch()
    let sessionID = Cookies.get("bsbsbo") || ""

    useEffect(() => {
        getAllPremblyProducts()
    }, [])

    let getAllPremblyProducts = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {},
            callback,
        }
        dispatch(allPremblyProductsRequest(data))
    }

    // let prodData = [
    //     {
    //         logo:bgcLogo,
    //         description:"In-depth screening of potential partners or employees - anytime and anywhere. ",
    //         url:"https://dev-bgc.prembly.com/BackgroundCheck/Requests/",
    //         link:"https://prembly.com/backgroundCheck",
    //     },
    //     {
    //         logo:idpassLogo,
    //         description:"Data, document, and biometric verification solutions to help you onboard new customers with ease. ",
    //         url:"https://dev-bgc.prembly.com/Identitypass/Verification/Data/",
    //         link:"https://prembly.com/identityPass",
    //     }
    // ]


    // let products = JSON.stringify ( data )
    // products = JSON.parse ( products )
    // console.log({allPremblyProductsState})
    return (
        <div className=" container-fluid px-md-4" style={{backgroundColor:'#f4f4f4'}}>
            {notif && notifVal && (
                <NotificationToast
                    title={notifTitle}
                    message={notif}
                    closeNotif={() => setNotifVal(!notifVal)}
                />
            )}

            {allPremblyProductsState?.isLoading
                ? props.changeLoadingState(true)
                : props.changeLoadingState(false)
            }


            {/* <div className="d-flex align-items-center justify-content-between table-header">
                <div className="col-md-5">
                    <h5>{tenantState?.resp?.tenant?.name} Products</h5>
                    <p>
                        Get access to all {tenantState?.resp?.tenant?.name}'s products!
                    </p>
                </div>
            </div>
            <br /> */}
            <br />
            <div  
            // style={{display:'grid',gridTemplateColumns:'repeat(3,1fr)','gap':'1rem','maxWidth':'1000px','border':'1px solid red',margin:'0 auto',
            // }}
            className='AllProductCardv2___main_container'
            >
                {
                    allPremblyProductsState?.resp?.data?.map((pro: any, i: number) => {
                        
                        return <div className='items'> <AllProductCardv2 
                        websiteLink={pro.link}
                        productLink={`${pro?.url}?session=${sessionID}`}
                        content={pro.description}
                        name={pro.name}
                        code={pro.code}
                        key={i}/> </div>
                    })
                }
            </div>
<br /><br /><br />
            {/* {!allPremblyProductsState?.isLoading &&
                <> */}
            {/* <div className="col-md-11">
                <div className="row py-4">
                    {allPremblyProductsState?.resp?.data?.map((pro: any, i: number) => (
                        <div className="col-md-4 pt-4" key={i}>
                            <div className="card rounded-5 px-2 py-3" >
                                <div className="card-body">
                                    <div className='d-flex flex-column justify-content-between' style={{ minHeight: "250px" }}>
                                        <div style={{ width: "200px" }}>
                                            <img className='w-100' src={pro['logo']} alt="product-logo" />
                                        </div>
                                        <div>
                                            <div className=' pt-4'>
                                                <p>
                                                    {pro['description']}
                                                    <a href={pro?.link} className='ms-1 link' target='_blank' rel="noreferrer" > Read More...</a>
                                                </p>
                                            </div>
                                            <a href={`${pro?.url}?session=${sessionID}`} target='_blank' className='btn rounded-5' rel="noreferrer"
                                                style={{ color: "#003241", border: "1px solid #003241" }}>
                                                <span className=' d-flex align-items-center py-2'>
                                                    <small>Visit Product</small>
                                                    <i className='ri-arrow-right-line ms-2' />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div> */}
        </div>
    )
}